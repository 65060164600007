import type { ServiceRequireSparePart } from '@laast-io/types'

export function calculateServiceSparePartsCost(
  parts: ServiceRequireSparePart[],
) {
  let cost = 0

  for (const part of parts) {
    if (typeof part.spare_part !== 'object')
      continue
    if (!part?.spare_part?.unitary_cost)
      continue

    const quantity = part.quantity ?? 1
    const price = part.spare_part.unitary_cost * quantity
    cost += price
  }

  return cost
}
